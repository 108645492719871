.App {
  text-align: center;
}


.center {
  display: flex;
  justify-content: center;
}


/*.particles {*/
/*  position: fixed;*/
/*  top: 0;*/
/*  right: 0;*/
/*  bottom: 0;*/
/*  left: 0;*/
/*  z-index: -1;*/
/*}*/
